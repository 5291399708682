<template>
  <div class="search-results" :class="{ 'expand': mode === 1, 'collapse': mode === 0 }" :style="{ height: currentHeight + 'px' }">
    <div class="search-results__header" @touchstart="handleTouchStart($event)" @touchmove="handleTouchMove" @touchend="handleTouchEnd">
      <div class="search-results__drag" v-if="mode !== 0"></div>
      <img src="@/assets/images/svg/up.svg" class="search-results__drag search-results__drag_up" v-else>
      <picture class="search-results__list-img">
        <source srcset="../assets/images/svg/list.svg" media="(min-width: 769px)">
        <source srcset="../assets/images/svg/list_white.svg" media="(max-width: 768px)">
        <img src="@/assets/images/svg/list.svg">
      </picture>
      <h2 class="search-results__heading">Отображено <span>{{ visibles.length }} из {{ list.length }}</span>
        {{ activeLayer === 'partners' ? 'партнеров' : 'объектов' }}
      </h2>
      <img src="@/assets/images/svg/close_white.svg" class="search-results__close" @click.stop="clearResults">
    </div>
    <ul class="search-results__list">
      <li v-for="(obj, index) in items" :key="index" @click="chooseObject(obj.id)" :class="{ visited: obj.visited }">
        <h3 class="search-results__name">
          {{ obj.name }}
        </h3>
        <p class="search-results__certificate" v-if="obj.is_sertified">
          <img src="@/assets/images/svg/star.svg">
          С сертификатом
        </p>
        <div v-if="obj.phone_1.length || obj.phone_2.length" style="display: flex; gap: 16px;">
          <p v-if="obj.phone_1.length" class="search-results__phone">
            {{ obj.phone_1 }}
          </p>
          <p v-if="obj.phone_2" class="search-results__phone">
            {{ obj.phone_2 }}
          </p>
        </div>
        <p class="search-results__address">
          {{ obj.address }}
        </p>
        <div v-if="obj.visited" class="search-results-badge__visibility">
          <p>Просмотрено</p>
        </div>
        <div v-if="isAuth" class="search-results__visibility" @click.stop="toggleVisible(obj.id)">
          <img src="@/assets/images/svg/eye-open_outline.svg" v-if="obj.is_show_on_map">
          <img src="@/assets/images/svg/eye-close_outline.svg" v-else>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios';
import EncodeDecodeFilter from '@/helpers/EncodeDecodeFilter';
import partnerFilter from '@/helpers/PartnerFiltes';
import facilityFilter from '@/helpers/FacilityFilter';

export default {
  name: 'SearchResults',
  data() {
    return {
      isAdmin: true,
      mode: 1,
      startPosition: null,
      items: [],
      currentHeight: window.innerHeight - 189, // Начальная высота панели
      minHeight: 55, // Минимальная высота панели
      maxHeight: window.innerHeight - 189 // Максимальная высота панели
    }
  },
  mounted() {
    if (this.$store.getters['getPartners'].length && this.$store.getters['getObjects'].length) {
      this.applyFilters(this.$route.path,
        this.$route.params.directionIDs,
        this.$route.params.filterIDs,
        this.$route.params.attributes,
        this.$route.params.certificate,
        this.$route.params.range,
        this.$route.params.filterIDsRange,
        this.$route.params.filterIDsAttributes,
        this.$route.params.attributesCertificate);
    } else {
      const interval = setInterval(() => {
        if (this.$store.getters['getPartners'].length && this.$store.getters['getObjects'].length) {
          this.applyFilters(this.$route.path,
            this.$route.params.directionIDs,
            this.$route.params.filterIDs,
            this.$route.params.attributes,
            this.$route.params.certificate,
            this.$route.params.range,
            this.$route.params.filterIDsRange,
            this.$route.params.filterIDsAttributes,
            this.$route.params.attributesCertificate);
          clearInterval(interval);
        }
      }, 500);
    }
    this.items = this.list.filter(obj => this.visibles.includes(obj.id));
  },
  beforeDestroy() {
    this.$store.dispatch('removeDot');
  },
  methods: {
    chooseObject(objectId) {
      this.$store.dispatch('setIsLoading', true);
      this.$store.dispatch('setActiveItem', objectId);
      this.$router.push(`/${this.activeLayer}/${objectId}`);
      this.$store.dispatch('setIsLoading', false);
    },
    handleTouchStart(event) {
      this.startPosition = event.touches[0].clientY;
    },
    handleTouchMove(event) {
      event.preventDefault(); // Предотвращаем стандартное поведение браузера

      const currentY = event.touches[0].clientY;
      const distance = this.mode ? currentY - this.startPosition : this.startPosition - currentY;
      const currentHeight = this.mode ? this.maxHeight - distance : this.minHeight + distance;

      if (currentHeight > this.minHeight && currentHeight < this.maxHeight) {
        this.currentHeight = currentHeight;
      }
    },
    handleTouchEnd(event) {
      const middle = this.maxHeight / 2;

      if (this.currentHeight > middle) {
        this.mode = 1;
        this.currentHeight = this.maxHeight;
      } else {
        this.mode = 0;
        this.currentHeight = this.minHeight;
      }

      this.startPosition = null;
    },
    clearResults() {
      this.$router.push(`/${this.activeLayer}`);
    },
    async toggleVisible(id) {
      let item;
      let endpoint;
      if (this.activeLayer === 'objects') {
        item = this.objects.find(obj => obj.id === id);
        endpoint = 'objects';
      } else if (this.activeLayer === 'partners') {
        item = this.partners.find(partner => partner.id === id);
        endpoint = 'partners';
      }
      item.is_show_on_map = !item.is_show_on_map;
      try {
        await axios.patch(`/api/v1/${endpoint}/${item.id}`, {
          ...item,
          business_regions: [...item.business_regions_ids],
          additional_curators: [...item.additional_curators_ids],
          products: [...item.product_ids]
        });
        this.$services.MessageService.success('Видимость успешно изменена');
      } catch (err) {
        console.log(err);
        this.$services.MessageService.warning('Произошла ошибка при изменении видимости');
      }
    },
    isVisible(objId) {
      return this.visibles.includes(objId);
    },
    /** Function with implementation to apply partner/object filters
     * @param {string} [path] - path in the address bar
     * @param {string} [directionIDs] - encoded destination identifiers of partners
     * @param {string | undefined} [filterIDs] - encoded Filter IDs
     * @param {boolean} [attributes] - attributes of a partner
     * @param {boolean | undefined} [certificate] - availability of a partner certificate
     * @param {string  | undefined} [range] - object year range
     * @param {string  | undefined} [filterIDsRange] - encoded filter IDs or feature year range
     * @param {string | boolean} [filterIDsAttributes] - encoded Filter IDs or attributes of a partner
     * @param {boolean} [attributesCertificate] - attributes of a partner or availability of a partner certificate
     */
    applyFilters(path, directionIDs, filterIDs, attributes, certificate, range, filterIDsRange, filterIDsAttributes, attributesCertificate) {
      const isPartners = !!path.includes('partners');
      let filtrationResult;
      if (isPartners) {
        filtrationResult = this.applyPartnersFilters(directionIDs, filterIDs, attributes, certificate, filterIDsAttributes, attributesCertificate);
      } else {
        filtrationResult = this.applyObjectsFilters(directionIDs, filterIDs, range, filterIDsRange);
      }

      if (!filtrationResult.length) {
        this.$services.MessageService.warning('По вашему запросу ничего не найдено');
      }

      this.$store.dispatch('setSearchResults', filtrationResult);
      this.$store.dispatch('applyFilters', filtrationResult);
    },
    /** A function that implements the functionality for filtering partners
    * @param {string} [directionIDs] - encoded destination identifiers of partners.
    * @param {string | undefined} [filterIDs] - encoded Filter IDs.
    * @param {boolean} [attributes] - attributes of a partner.
    * @param {boolean | undefined} [certificate] - availability of a partner certificate.
    * @param {string | boolean} [filterIDsAttributes] - encoded Filter IDs or attributes of a partner
    * @param {boolean} [attributesCertificate] - attributes of a partner or availability of a partner certificate
    */
    applyPartnersFilters(directionIDs, filterIDs, attributes, certificate = null, filterIDsAttributes, attributesCertificate) {
      const items = this.partners;
      const itemTypes = this.partnerTypes;
      const directions = this.directions;
      let activeDirections = [];

      if (!filterIDs && !attributes) {
        if (filterIDsAttributes && (filterIDsAttributes.includes('true') || filterIDsAttributes.includes('false'))) {
          attributes = filterIDsAttributes;
          certificate = attributesCertificate;
        } else {
          filterIDs = filterIDsAttributes;
          attributes = attributesCertificate;
        }
      }

      if (certificate) {
        certificate = !!certificate.includes('true');
      }

      // Decoding parameters of destination identifiers and partner filters from the address bar
      const decode = EncodeDecodeFilter.decode(directionIDs, filterIDs || [], '');
      const filter = {
        attr: !!attributes.includes('true'),
        certificate,
        filters: decode.filterGuids.reduce((a, v) => {
          a.typeNames = itemTypes.includes(itemTypes.find(x => x.id === v)) ? [...a.typeNames, itemTypes.find(x => x.id === v).name] : a.typeNames
          a.productIds = itemTypes.includes(itemTypes.find(x => x.id === v)) ? a.productIds : [...a.productIds, v]
          return a
        }, {
          typeNames: [],
          productIds: []
        })
      }
      for (let i = 0; i < decode.directionGuids.length; i++) {
        const item = directions.find(x => x.id === decode.directionGuids[i]);
        if (item) {
          activeDirections = activeDirections.length ? [...activeDirections, item.code] : [item.code];
        }
      }
      // eslint-disable-next-line no-undef
      const filtrationResult = partnerFilter(items, filter, activeDirections);
      return filtrationResult; /** @type {Array} result of partner filtering */
    },
    /**
     * @param {string} [directionIDs] - encoded destination identifiers of partners.
     * @param {string | undefined} [filterIDs] - encoded Filter IDs.
     * @param {string  | undefined} [range] - object year range
     * @param {string  | undefined} [filterIDsRange] - encoded filter IDs or feature year range
     */
    applyObjectsFilters(directionIDs, filterIDs, range, filterIDsRange) {
      const items = this.objects;
      const itemTypes = this.objectTypes;
      const directions = this.directions;
      let activeDirections = [];

      if (!filterIDs) {
        if (filterIDsRange && filterIDsRange.includes('-')) {
          range = filterIDsRange;
        } else {
          filterIDs = filterIDsRange;
        }
      }

      const decode = EncodeDecodeFilter.decode(directionIDs, filterIDs || [], range || '');

      for (let i = 0; i < decode.directionGuids.length; i++) {
        const item = directions.find(x => x.id === decode.directionGuids[i]);
        if (item) {
          activeDirections = activeDirections.length ? [...activeDirections, item.code] : [item.code];
        }
      }

      const filter = decode.filterGuids.reduce((a, v) => {
        a.typeNames = itemTypes.includes(itemTypes.find(x => x.id === v)) ? [...a.typeNames, itemTypes.find(x => x.id === v).name] : a.typeNames
        a.productIds = itemTypes.includes(itemTypes.find(x => x.id === v)) ? a.productIds : [...a.productIds, v]
        return a
      }, {
        typeNames: [],
        productIds: []
      })

      const filtrationResult = facilityFilter(items, filter, decode.range, activeDirections)
      return filtrationResult; /** @type {Array} result of objects filtering */
    }
  },
  watch: {
    visibles() {
      this.items = this.list.filter(obj => this.visibles.includes(obj.id));
    }
  },
  computed: {
    activeLayer() {
      return this.$store.getters['getActiveLayer'];
    },
    visibles() {
      return this.$store.getters['getVisibles'];
    },
    objects() {
      return this.$store.getters['getObjects'];
    },
    objectTypes() {
      return this.$store.getters['getObjectTypes'];
    },
    partners() {
      return this.$store.getters['getPartners'];
    },
    partnerTypes() {
      return this.$store.getters['getPartnerTypes'];
    },
    list() {
      return this.$store.getters['getSearchResults'];
    },
    isAuth() {
      return this.$store.getters['isAuth'];
    },
    directions() {
      return this.$store.getters['getDirections'];
    },
    getPartnerFilter() {
      return this.$store.getters['getPartnerFilter']
    }
  }
}
</script>

<style scoped>
.search-results {
  display: flex;
  flex-direction: column;
  transition: height .15s ease-out;
  overflow-y: hidden;
}

.search-results__header {
  background-color: #F2F2F2;
  border-top: 1px solid #BFBFBF;
  border-bottom: 1px solid #BFBFBF;
  padding-left: 8px;
  min-height: 42px;
  display: flex;
  justify-content: left;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.search-results__heading {
  font-size: 16px;
  line-height: 22px;
}

.search-results__heading span {
  font-weight: bold;
}

.search-results__list {
  background-color: #fff;
  overflow-y: auto;
  height: calc(100vh - 260px);
}

.search-results__list::-webkit-scrollbar {
  width: 9px;
}

.search-results__list::-webkit-scrollbar-thumb {
  background-color: black;
  border-right: 4px #fff solid;
  background-clip: padding-box;
}

.search-results__list-img {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.search-results__list li {
  position: relative;
  padding: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #E5E5E5;
  transition: background-color .3s ease;
  cursor: pointer;
}

.search-results__list .visited {
  background-color: #EFEFEF;
}

.search-results__name {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 5px;
  padding-right: 30px;
}

.search-results__certificate,
.search-results__address,
.search-results__phone {
  font-size: 16px;
  line-height: 20px;

  color: #8C8C8C;
}

.search-results__certificate {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-bottom: 5px;
}

.search-results__certificate img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

.search-results__visibility {
  position: absolute;
  top: 20px;
  right: 20px;
}

.search-results__visibility img {
  width: 20px;
}

.search-results-badge__visibility {
  position: absolute;
  top: 5px;
  right: 20px;
}

.search-results-badge__visibility p {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  color: #8C8C8C;
}

.search-results__drag {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translateX(-50%);
  width: 22px;
  height: 3px;
  background-color: rgba(255, 255, 255, .7);
  display: none;
}

.search-results__drag_up {
  width: 22px;
  height: 6px;
  background-color: transparent;
}

.search-results__close {
  position: absolute;
  top: 14px;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: none;
}

@media (max-width: 1023px) {
  .search-results {
    position: fixed;
    bottom: 0;
    width: 100vw;
  }

  .search-results.expand {
    overflow-y: scroll;
  }

  .search-results.collapse {
    height: 55px;
  }

  .search-results__header {
    background-color: #000;
    color: #fff;
    min-height: 56px;
  }

  .search-results__heading {
    font-size: 18px;
  }

  .search-results__list {
    flex-grow: 1;
  }

  .search-results__drag,
  .search-results__close {
    display: block;
  }
}
</style>
